const actions = {

}
const mutations = {

}
const getters = {

}

const state = {
    languages: ['en'],
    projectId: '611aceb7-8f09-4fce-91bd-19ead22f15b8'
    //identificationActivity: ''
};

export default {
  namespaced: true,
  state,
    actions,
    mutations,
    getters
}
